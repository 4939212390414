<template>
    <div class="container container-lead bg-white border-radius-4">
        <div class="card-header p-0">
            <div class="d-flex align-items-center justify-content-between w-100 spacing-b-15">

                <div class="d-flex justify-content-between align-items-center">

                    <div class="d-flex justify-content-between align-items-center mr-10" style="margin-bottom: 0;" v-if="isProductionReport">
                        <div>
                            <h5>Generate Production Report for Date : </h5>
                        </div>
                        <div class="bd-highlight datepicker-dropdown datepicker-dropdown mr-10">
                            <vc-date-picker-v2
                                ref="date"
                                v-model="dateValue"
                                @input="dateError = ''"
                                @change="dateError = ''"
                                min="2020-01-01"
                                :max="new Date().toISOString().split('T')[0]"
                                v-bind:style="dateError != '' ? 'border: 1px solid red;' : 'border:none;'"
                            />
                        </div>
                        <div class="search-buttons-block d-flex align-items-center ml-10">
                            <div class="bd-highlight">
                                <button
                                type="button"
                                class="btn btn-light-info"
                                @click="generateReport('old')"
                                >
                                Old Report
                                </button>

                                <button
                                type="button"
                                class="btn btn-light-info"
                                @click="generateReport('new')"
                                >
                                NSB Report
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    <div class="bd-highlight datepicker-dropdown" v-if="isFirstDates">
                        <vc-date-picker-range
                            v-model="dates"
                            @change="handleDateFilter"
                            :clearable="clearableDate"
                        />   
                    </div>

                    <div class="form-select-custom form-select-align">
                        <div class="d-flex align-items-center multiselect-group">

                            <div class="multiselect-dropdown-custom" v-if="isFirstUnderwriter">
                                <el-select
                                    filterable
                                    multiple
                                    collapse-tags
                                    @change="rFilters.page = 1"
                                    v-model="rFilters.underwriter"
                                    placeholder="Select Underwritter"
                                    size="large"
                                    style="width: 100%"
                                >
                                    <el-option
                                    v-for="item in underwriters"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                                </el-select>
                            </div>
                            
                            <div class="multiselect-dropdown-custom multiselect-simple" :class="multiSelectCustomClass" v-if="isPolicySalesAgent">
                                <el-select
                                    filterable
                                    :multiple="isPolicySalesAgentMultiple"
                                    :collapse-tags="collapseTag || isCollapseMultiSelect"
                                    v-model="rFilters.agent"
                                    @change="rFilters.page = 1"
                                    :placeholder="policySalesAgentPlaceHolder"
                                    style="width: 100%"
                                    :collapse-tags-tooltip="collapseTag || isCollapseMultiSelect"
                                >
                                    <el-option
                                    v-for="item in salesAgents"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                                </el-select>
                            </div>
                            
                            <div class="multiselect-dropdown-custom multiselect-simple" v-if="isPaymentType">
                                <el-select
                                    filterable
                                    v-model="rFilters.payment_type"
                                    @change="rFilters.page = 1"
                                    placeholder="All Transaction Type"
                                    :multiple="isPaymentTypeMultiple"
                                    size="large"
                                    style="width: 100%"
                                >
                                    <el-option
                                        v-if="!isPaymentTypeMultiple"
                                        label="All Transaction Type"
                                        :value="-1"
                                    />
                                    <el-option
                                        v-for="item in paymentTypes"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                </el-select>
                            </div>

                            <div class="multiselect-dropdown-custom multiselect-simple" v-if="isShowYearOnly">
                                <el-select
                                    filterable
                                    v-model="currentYear"
                                    @change="handleCurrentYear"
                                    collapse-tags
                                    size="large"
                                    style="width: 100%"
                                >
                                    <el-option
                                        v-for="item in years"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    />
                                </el-select>
                            </div>
                            <div class="multiselect-dropdown-custom multiselect-simple" v-if="isFirstPolicyStatus">
                                <el-select
                                    filterable
                                    v-model="rFilters.status"
                                    @change="rFilters.page = 1"
                                    multiple
                                    collapse-tags
                                    placeholder="Select Policy Status"
                                    size="large"
                                    style="width: 100%"
                                >
                                    <el-option
                                        v-for="item in policyStatuses"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                </el-select>
                            </div>

                            <div class="multiselect-dropdown-custom multiselect-simple" :class="multiSelectCustomClass" v-if="isFirstLeadStatus">
                                <el-select
                                    filterable
                                    v-model="rFilters.lead_status_id"
                                    @change="rFilters.page = 1"
                                    placeholder="Select Lead Status"
                                    size="large"
                                    style="width: 100%"
                                    :collapse-tags-tooltip="isMultiLeadStatus"
                                    :multiple="isMultiLeadStatus"
                                    :collapse-tags="isMultiLeadStatus"
                                >
                                    <el-option label="Lead Status" value="all" />
                                    <el-option
                                    v-for="item in leadStatuses"
                                    :key="item.id"
                                    :label="item.value"
                                    :value="item.id"
                                    />
                                </el-select>
                            </div>

                            <div class="multiselect-dropdown-custom multiselect-simple" v-if="isShowSalesOptions">
                                <el-select
                                    filterable
                                    v-model="rFilters.sales"
                                    @change="rFilters.page = 1; rFilters.leads = 'all'"
                                    placeholder="Select Sales"
                                    size="large"
                                    style="width: 100%"
                                >
                                    <el-option value="all" label="Select Sales" />
                                    <el-option
                                        v-for="item in salesOptions"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                </el-select>
                            </div>
                            <div class="multiselect-dropdown-custom multiselect-simple" v-if="isShowLeadsOptions">
                                <el-select
                                    filterable
                                    v-model="rFilters.leads"
                                    @change="rFilters.page = 1; rFilters.sales = 'all'"
                                    placeholder="Select Leads"
                                    size="large"
                                    style="width: 100%"
                                >
                                    <el-option value="all" label="Select Leads" />
                                    <el-option
                                        v-for="item in leadsOptions"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                </el-select>
                            </div>
                            <div class="multiselect-dropdown-custom" v-if="isUnderwriter">
                                <el-select
                                    filterable
                                    multiple
                                    collapse-tags
                                    @change="rFilters.page = 1"
                                    v-model="rFilters.agent"
                                    placeholder="Select Underwritter"
                                    size="large"
                                    style="width: 100%"
                                >
                                    <el-option
                                    v-for="item in underwriters"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                                </el-select>
                            </div>
                            

                            <div class="multiselect-dropdown-custom" v-if="isAllUser">
                                <el-select
                                    filterable
                                    multiple
                                    collapse-tags
                                    @change="rFilters.page = 1"
                                    v-model="rFilters.user_id"
                                    placeholder="Select User"
                                    size="large"
                                    style="width: 100%"
                                >
                                    <el-option
                                    v-for="item in allUsers"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                                </el-select>
                            </div>
                            <template v-if="isLeadBySource">
                                <div class="multiselect-dropdown-custom multiselect-simple">
                                    <el-select
                                        filterable
                                        v-model="rFilters.lead_source"
                                        @change="rFilters.page = 1"
                                        placeholder="Select Lead Source"
                                        size="large"
                                        style="width: 100%"
                                    >
                                        <el-option
                                        v-for="item in leadSources"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                        />
                                    </el-select>
                                </div>
                                <div class="multiselect-dropdown-custom multiselect-simple">
                                    <el-select
                                        filterable
                                        v-model="rFilters.lead_status_id"
                                        @change="rFilters.page = 1"
                                        placeholder="Select Lead Status"
                                        size="large"
                                        style="width: 100%"
                                    >
                                        <el-option label="Lead Status" value="all" />
                                        <el-option
                                        v-for="item in leadStatuses"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                        />
                                    </el-select>
                                </div>
                            </template>
                        </div>
                    </div>

                </div>

                <div class="search-block d-flex align-items-center justify-content-end">

                    <div class="search-field-block search-block-tooltip popper-custom" v-if="showSearchKeyword">
                        <div class="d-flex align-items-center position-relative">
                            <span class="svg-icon svg-icon-1 position-absolute ms-6">
                                <inline-svg src="media/icons/duotune/general/gen021.svg" />
                            </span>
                            <Popper
                                hover="false"
                                placement="bottom"
                                arrow
                                disableClickAway
                            >
                                <input
                                type="text"
                                v-model="search"
                                @keydown.enter.prevent="searchKeyword"
                                class="form-control ps-15"
                                :placeholder="keywordPlaceHolder"
                                />
                                <template #content>
                                <div
                                    class="card card-flush"
                                    style="
                                    box-shadow: 0px 10px 31px 10px rgba(82 63 105, 0.5);
                                    "
                                >
                                    <div class="card-body py-5">
                                    Search using Quote Ref. No
                                    </div>
                                </div>
                                </template>
                            </Popper>
                        </div>
                    </div>

                    <div class="export-btn d-flex align-items-center" v-if="showFirstExport">
                        <button
                            :data-kt-indicator="loadExport ? 'on' : null"
                            @click="exportReport"
                            class="btn btn-info btn-danger"
                            type="button"
                        >
                            <span v-if="!loadExport" class="indicator-label">
                                <img
                                    src="@/assets/images/export-xls.svg"
                                    alt="Export"
                                />
                            </span>
                            <span v-if="loadExport" class="indicator-progress">
                                Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>

                    <div class="search-buttons-block d-flex align-items-center">
                        <button
                            v-if="showSearchKeyword"
                            type="button"
                            class="btn btn-light-info cursor-pointer"
                            @click="searchKeyword"
                        >
                            Search
                        </button>
                        <div class="ms-4 search-buttons-block d-flex align-items-center" v-if="showReset">
                            <div class="bd-highlight">
                                <button
                                    type="button"
                                    class="btn btn-light-info"
                                    @click="reset"
                                >
                                Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="d-flex align-items-center justify-content-between w-100 spacing-b-15" v-if="showSecondLine">

                <div class="d-flex justify-content-between align-items-center">

                    <div class="form-select-align">
                        <div class="d-flex align-items-center multiselect-group">

                            <div class="multiselect-dropdown-custom multiselect-simple" v-if="isSecondPolicySalesAgent">
                                <el-select
                                    filterable
                                    v-model="rFilters.agent"
                                    @change="rFilters.page = 1"
                                    placeholder="Select Policy Sales Agent"
                                    size="large"
                                    style="width: 100%"
                                >
                                    <el-option value="all" label="Select Sales Agent" />
                                    <el-option
                                    v-for="item in salesAgents"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                                </el-select>
                            </div>

                            <div class="multiselect-dropdown-custom multiselect-simple" v-if="isSecondLeadSources">
                                <el-select
                                    filterable
                                    v-model="rFilters.lead_source"
                                    @change="rFilters.page = 1"
                                    placeholder="Select Lead Source"
                                    size="large"
                                    style="width: 100%"
                                >
                                    <el-option
                                    v-for="item in leadSources"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                                </el-select>
                            </div>

                            <div class="multiselect-dropdown-custom multiselect-simple" v-if="isSecondPolicyStatus">
                                <el-select
                                    filterable
                                    v-model="rFilters.status"
                                    @change="rFilters.page = 1"
                                    collapse-tags
                                    placeholder="Select Policy Status"
                                    size="large"
                                    style="width: 100%"
                                >
                                    <el-option
                                        v-for="item in policyStatuses"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                </el-select>
                            </div>
                            
                            <div class="multiselect-dropdown-custom multiselect-simple" v-if="showInvoiceType">
                                <el-select
                                    filterable
                                    v-model="invoiceType"
                                    @change="handleInvoiceType"
                                    size="large"
                                    style="width: 100%"
                                >
                                    <el-option
                                    v-for="item in invoiceTypes"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                                </el-select>
                            </div>

                            <template v-if="isLeadBySource">
                                <div class="multiselect-dropdown-custom multiselect-simple">
                                    <el-select
                                        filterable
                                        v-model="rFilters.utm_source"
                                        @change="rFilters.page = 1"
                                        placeholder="All UTM Source"
                                        size="large"
                                        style="width: 100%"
                                    >
                                        <el-option
                                        v-for="item in utmSources"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                        />
                                    </el-select>
                                </div>
                                <div class="multiselect-dropdown-custom multiselect-simple">
                                    <el-select
                                        filterable
                                        v-model="rFilters.utm_medium"
                                        @change="rFilters.page = 1"
                                        placeholder="All UTM Source"
                                        size="large"
                                        style="width: 100%"
                                    >
                                        <el-option
                                        v-for="item in utmMediums"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                        />
                                    </el-select>
                                </div>
                                <div class="multiselect-dropdown-custom multiselect-simple">
                                    <el-select
                                        filterable
                                        v-model="rFilters.utm_campaign"
                                        @change="rFilters.page = 1"
                                        placeholder="All UTM Campaign"
                                        size="large"
                                        style="width: 100%"
                                    >
                                        <el-option
                                        v-for="item in utmCampaigns"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                        />
                                    </el-select>
                                </div>
                                <div class="multiselect-dropdown-custom multiselect-simple">
                                    <el-select
                                        filterable
                                        v-model="rFilters.utm_content"
                                        @change="rFilters.page = 1"
                                        placeholder="All UTM Content"
                                        size="large"
                                        style="width: 100%"
                                    >
                                        <el-option
                                        v-for="item in utmContents"
                                        :key="item.id"
                                        :label="item.label"
                                        :value="item.value"
                                        />
                                    </el-select>
                                </div>
                            </template>
                            
                        </div>
                    </div>

                </div>

                <div class="search-block d-flex align-items-center justify-content-end">


                    <div class="export-btn d-flex align-items-center me-4" v-if="showSecondExport">
                        <button
                            :data-kt-indicator="loadExport ? 'on' : null"
                            @click="exportReport"
                            class="btn btn-info btn-danger"
                            type="button"
                        >
                            <span v-if="!loadExport" class="indicator-label">
                                <img
                                    src="@/assets/images/export-xls.svg"
                                    alt="Export"
                                />
                            </span>
                            <span v-if="loadExport" class="indicator-progress">
                                Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>

                    <div class="search-buttons-block d-flex align-items-center">
                        <div class="ms-4 search-buttons-block d-flex align-items-center" v-if="showSecondReset">
                            <div class="bd-highlight">
                                <button
                                    type="button"
                                    class="btn btn-light-info"
                                    @click="reset"
                                >
                                Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="d-flex align-items-center justify-content-between w-100 spacing-b-15" v-if="showThirdLine">

                <div class="d-flex justify-content-between align-items-center">

                    <div class="form-select-align">
                        <div class="d-flex align-items-center multiselect-group">
                            <!--  -->
                        </div>
                    </div>

                </div>

                <div class="search-block d-flex align-items-center justify-content-end">


                    <div class="export-btn d-flex align-items-center me-4" v-if="showThirdExport">
                        <button
                            :data-kt-indicator="loadExport ? 'on' : null"
                            @click="exportReport"
                            class="btn btn-info btn-danger"
                            type="button"
                        >
                            <span v-if="!loadExport" class="indicator-label">
                                <img
                                    src="@/assets/images/export-xls.svg"
                                    alt="Export"
                                />
                            </span>
                            <span v-if="loadExport" class="indicator-progress">
                                Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                        </button>
                    </div>

                    <div class="search-buttons-block d-flex align-items-center">
                        <div class="ms-4 search-buttons-block d-flex align-items-center" v-if="showThirdReset">
                            <div class="bd-highlight">
                                <button
                                    type="button"
                                    class="btn btn-light-info"
                                    @click="reset"
                                >
                                Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, onMounted, watch } from 'vue'
import { dates, rFilters } from '@/store/composable/Reports'
import { useUpdateCustomerData } from '@/store/composable/Customer'
import { useRoleBasedConditions } from '@/store/composable/User'
import Swal from "sweetalert2/dist/sweetalert2.js";

const props = defineProps({
    isFirstDates: {
        type: Boolean,
        required: false,
        default: false
    },
    isPolicySalesAgent: {
        type: Boolean,
        required: false,
        default: false
    },
    isLeadSalesAgent: {
        type: Boolean,
        required: false,
        default: false
    },
    isPolicySalesAgentMultiple: {
        type: Boolean,
        required: false,
        default: true
    },
    policySalesAgentPlaceHolder: {
        type: String,
        required: false,
        default: 'Select Policy Sales Agent'
    },
    keywordPlaceHolder: {
        type: String,
        required: false,
        default: 'Enter Quote Reference No'
    },
    isFirstPolicyStatus: {
        type: Boolean,
        required: false,
        default: false
    },
    isUnderwriter: {
        type: Boolean,
        required: false,
        default: false
    },
    isFirstUnderwriter: {
        type: Boolean,
        required: false,
        default: false
    },
    isPaymentType: {
        type: Boolean,
        required: false,
        default: false
    },
    isPaymentTypeMultiple: {
        type: Boolean,
        required: false,
        default: false
    },
    isShowYearOnly: {
        type: Boolean,
        required: false,
        default: false
    },
    isProductionReport: {
        type: Boolean,
        required: false,
        default: false
    },
    isAllUser: {
        type: Boolean,
        required: false,
        default: false
    },
    showFirstExport: {
        type: Boolean,
        required: false,
        default: false
    },
    isShowSalesOptions: {
        type: Boolean,
        required: false,
        default: false
    },
    isShowLeadsOptions: {
        type: Boolean,
        required: false,
        default: false
    },
    isSecondPolicySalesAgent: {
        type: Boolean,
        required: false,
        default: false
    },
    showReset: {
        type: Boolean,
        required: false,
        default: false
    },
    showSearchKeyword: {
        type: Boolean,
        required: false,
        default: false
    },
    showSecondLine: {
        type: Boolean,
        required: false,
        default: false
    },
    showInvoiceType: {
        type: Boolean,
        required: false,
        default: false
    },
    showSecondExport: {
        type: Boolean,
        required: false,
        default: false
    },
    showSecondReset: {
        type: Boolean,
        required: false,
        default: false
    },
    isSecondPolicyStatus: {
        type: Boolean,
        required: false,
        default: false
    },
    isSecondPolicyStatusAccounts: {
        type: Boolean,
        required: false,
        default: false
    },
    isSecondLeadSources: {
        type: Boolean,
        required: false,
        default: false
    },
    linkType: {
        type: String,
        required: false,
         default: ''
    },
    isLeadBySource: {
        type: Boolean,
        required: false,
        default: false
    },
    showThirdLine: {
        type: Boolean,
        required: false,
        default: false
    },
    showThirdExport: {
        type: Boolean,
        required: false,
        default: false
    },
    showThirdReset: {
        type: Boolean,
        required: false,
        default: false
    },
    clearableDate: {
        type: Boolean,
        required: false,
        default: true
    },
    isFirstLeadStatus: {
        type: Boolean,
        required: false,
        default: false
    },
    isLostLeadStatus: {
        type: Boolean,
        required: false,
        default: false
    },
    extraParams: {
        type: Object,
        requried: false,
    },
    showSystemInAgentList: {
        type: Boolean,
        required: false,
        default: true
    },
    isCollapseMultiSelect: {
        type: Boolean,
        required: false,
        default: false
    },
    multiSelectCustomClass: {
        type: Text,
        required: false,
        default: ''
    },
    isMultiLeadStatus: {
        type: Boolean,
        required: false,
        default: false
    }
})

const emit = defineEmits(['reset', 'report'])

const policyStatuses = ref([])
const underwriters = ref([])
const salesAgents = ref([])
const paymentTypes = ref([])
const leadSources = ref([])
const leadStatuses = ref([])
const utmSources = ref([])
const utmMediums = ref([])
const utmCampaigns = ref([])
const utmContents = ref([])
const allUsers = ref([])
const search = ref('')
const invoiceType = ref('all')
const dateValue = ref('')
const dateError = ref('')
const currentYear = ref(new Date().getFullYear())

const loadExport = ref(false)
const collapseTag = ref(false)

watch(() => rFilters.value, async(filter) => {
    // console.log(filter)
    if(filter.agent.length > 3)
        collapseTag.value = true
    else
        collapseTag.value = false
}, { deep: true })

const { ApprovedInvoicedSATLReport, ApprovedInvoicedAAMQReport, ApprovedInvoiceASMReport } = useRoleBasedConditions()

const years = computed(() => {
    const year = new Date().getFullYear()
    return Array.from({length: year - 2017}, (value, index) => year - index)
})

const invoiceTypes = computed(() => {
    const data = [
        { label: 'All Invoice Types', value: 'all' },
        { label: 'New', value: 'new' },
        { label: 'Amend', value: 'amend' },
        { label: 'Manual Order', value: 'manual_order' }
    ]

    if(props.isPaymentTypeMultiple)
        return data.filter(x => x.value != 'all')

    return data
})

const salesOptions = computed(() => {
    const data = [{
        value: 'total-sales', 
        label: 'Total Sales'
      },
      {
        value: 'total-new-sales', 
        label: 'New Sales'
      },
      {
        value: 'total-renewal-sales', 
        label: 'Renewal Sales'
      },
      {
        value: 'total-llr-sales', 
        label: 'Lost Lead Renewal Sales'
      },
      {
        value: 'lifetime-renewal-leads', 
        label: 'Current Year Renewal Sales'
      }]

      return data
})

const leadsOptions = computed(() => {
    const data = [{
        value: 'total-leads', 
        label: 'Total Leads'
      },
      {
        value: 'total-new-leads', 
        label: 'New Leads'
      },
      {
        value: 'total-renewal-leads', 
        label: 'Renewal Leads'
      },
      {
        value: 'total-llr-leads', 
        label: 'Lost Lead Renewals'
      },
      {
        value: 'total-lost-leads', 
        label: 'Lost Leads'
      },
      {
        value: 'total-lrl-leads', 
        label: 'Lead Renewal Lost'
      },
      {
        value: 'lifetime-renewal-leads', 
        label: 'Previous Year Renewal Leads'
      }]

      return data
})

function handleDateFilter(e) {
    let fDates = e
    if(!e) fDates = []

    rFilters.value.page = 1

    if(fDates && fDates.length > 0) {
        rFilters.value.start_date = fDates[0]
        rFilters.value.end_date = fDates[1]
    } else {
        rFilters.value.start_date = ''
        rFilters.value.end_date = ''
    }

    currentYear.value = ''
}

async function exportReport() {
    loadExport.value = true
    let data = {
        ...rFilters.value,
        linkType: props.linkType,
    }

    if(props.extraParams) {
        data =  {
            ...data,
            ...props.extraParams
        }
    }
    if(['marketing-report', 'marketing-report-agent','renewal-status','lost-lead-report','agent-escalations','underwriter-escalations', 'sales-tracking-report','agent-lead-activity-report'].includes(props.linkType)) {
        data = {
            ...data,
            export: true
        }
        const link = await useUpdateCustomerData(data)
        loadExport.value = false
        window.open(link.data.data.report)
    } else {
        const link = await useUpdateCustomerData(data)
        loadExport.value = false
        window.open(link.data.data.data)
    }
        
}

onMounted(() => {
    callApi()
})

async function callApi() {
    if(props.isPolicySalesAgent || props.isSecondPolicySalesAgent) {
        const salesAgentsList = await useUpdateCustomerData({ linkType: 'agent-list-dom', type: 'Car', isActive: false, isArchived: false, isLeadSalesAgent: props.isLeadSalesAgent })
        salesAgents.value = salesAgentsList.data.data.agents
    }
    if(props.isFirstPolicyStatus || props.isSecondPolicyStatus) {
        const policyStatusList = await useUpdateCustomerData({ linkType: 'master-policy-status' })
        const data = policyStatusList.data.data
        if(props.isSecondPolicyStatusAccounts) {
            const filterd = data.filter(x => [2, 7, 4, 10].includes(x.value))
            policyStatuses.value = filterd
        }
        else 
            policyStatuses.value = data
    }
    if(props.isFirstLeadStatus){
        
        if(props.isLostLeadStatus){
            leadStatuses.value = [
                { value:'LOST LEAD', id:'6' },
                { value:'LEAD RENEWAL LOST', id:'8' }
            ]
        }
        else {
            const data = await useUpdateCustomerData({ linkType: 'master-lead-status' })
            console.log('LeadStatus',data)
            leadStatuses.value = data.data.data.statuses
        }
    }
    if(props.isUnderwriter || props.isFirstUnderwriter) {
        const underwritersList = await useUpdateCustomerData({ linkType: 'master-underwriter-list' })
        underwriters.value = underwritersList.data.data.agents
    }
    if(props.isPaymentType) {
        const paymentTypeList = await useUpdateCustomerData({ linkType: 'payment-types' })
        paymentTypes.value = paymentTypeList.data.data.types
    }
    if(props.isSecondLeadSources) {
        const leadSourcesList = await useUpdateCustomerData({ linkType: 'lead-source-filter' })
        leadSources.value = leadSourcesList.data.data.data
    }
    if(props.isLeadBySource) {
        const leadBySrouce = await useUpdateCustomerData({ linkType: 'lead-by-source-filter' })
        leadSources.value = leadBySrouce.data.data.data.lead_sources
        leadStatuses.value = leadBySrouce.data.data.data.lead_statuses
        utmSources.value = leadBySrouce.data.data.data.utm_sources
        utmMediums.value = leadBySrouce.data.data.data.utm_medium
        utmCampaigns.value = leadBySrouce.data.data.data.utm_campaign
        utmContents.value = leadBySrouce.data.data.data.utm_content
    }
    if(props.isAllUser) {
        const allUserList = await useUpdateCustomerData({ linkType: 'user-list-for-dom' })
        allUsers.value = allUserList.data.data.users
    }
}

function handleCurrentYear() {
    const start = `${currentYear.value}-01-01`
    const end = `${currentYear.value}-12-31`
    rFilters.value.page = 1
    dates.value = [start, end]
    rFilters.value.start_date = start
    rFilters.value.end_date = end
}

function handleInvoiceType() {
    rFilters.value.page = 1
    switch(invoiceType.value) {
        case 'new': 
            rFilters.value.is_discrepancy = 0   
            rFilters.value.is_amend = 0   
            rFilters.value.is_manual_order = null 
            break
        case 'amend': 
            rFilters.value.is_discrepancy = null 
            rFilters.value.is_amend = 1
            rFilters.value.is_manual_order = null 
            break
        case 'manual_order': 
            rFilters.value.is_discrepancy = null 
            rFilters.value.is_amend = -1
            rFilters.value.is_manual_order = 1 
            break
        case 'discrepancy': 
            rFilters.value.is_discrepancy = 2 
            rFilters.value.is_amend = -1
            rFilters.value.is_manual_order = [0, 1]
            break
        default: 
            rFilters.value.agent = 'mine'    
            rFilters.value.payment_type = -1    
            rFilters.value.is_amend = -1    
            rFilters.value.is_discrepancy = null
            rFilters.value.is_manual_order = null
            rFilters.value.agent = ApprovedInvoiceASMReport ? 'all' : 'mine'
            break
    }
}

function searchKeyword() {
    rFilters.value.page = 1
    rFilters.value.keyword = search.value
}

function reset() {
    currentYear.value = new Date().getFullYear()
    search.value = ''
    emit('reset')
}

async function generateReport(type) {
    if(dateValue.value) {
        const response = await useUpdateCustomerData({ linkType: 'generate-production-report', date: dateValue.value, type })
        Swal.fire({
              text: "Production report generation request has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
        dateValue.value = ''
    } else {
        dateError.value = 'error'
    }
}
</script>
