<template>
    <div class="card-body card-custom-spacing-tblf-10 bg-white mt-5" v-if="!loading">
        <Headers 
            v-if="showTableTotal"
            :total="tableTotal"
            :message="tableMessage"
        />
        <vc-tables 
            v-if="tableType == 'default'"
            :headers="dataHeaders"
            :data="data"
            :is-sr="isSr"
            :srs="srs"
            :show-actions="false"
            :is-download-header="isDownloadHeader"
            @showTasks="showTasks"
        />

        <el-table
            v-if="tableType == 'admin-dashboard'"
            :data="data"
            class="
              table-hover table-bai-striped table-row-bordered border gy-7 gs-7 mb-0
            "
          >
            <el-table-column fixed width="100">
              <template #header>
                <span class="d-block fs-6 fw-bolder">Sr.# </span>
              </template>
              <template #default="scope">
                {{ scope.$index + (srs + 1) }}
              </template>
            </el-table-column>
            <template v-for="(head, j) in dataHeaders" :key="j">
              <el-table-column :fixed="j === 0" width="130">
                <template #header>
                  <span class="d-block fs-6 fw-bolder">{{ head.label }}</span>
                </template>
                <template #default="scope">
                  <p :style="`font-weight: ${ scope.$index == (data.length - 1) || j === 0 ? 'bold !important' : 'normal'}; 
                              color: ${ scope.$index == (data.length - 1) || j === 0 ? '#222261 !important' : '#000' }`"
                        >
                        {{ scope.row[head.value] }}
                        </p>
                </template>
              </el-table-column>
            </template>
          </el-table>

          <AccountTable 
            v-if="tableType == 'accounts'"
            :headers="dataHeaders"
            :data="data"
            :is-sr="isSr"
            :srs="srs"
            :counts="counts"
          />

    </div>
    <div v-else>
        <div class="card">
            <div class="card-body">Loading Information Please Wait...</div>
        </div>
    </div>
    <div class="card-footer bg-white" v-if="showFooter">
            <div class="row">
                <div class="col-4">
                    <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <div class="dataTables_length">
                            <el-select
                                v-model="rFilters.per_page"
                                class="perPages"
                                @change="rFilters.page = 1"
                            >
                                <el-option v-for="page in per_pages" :key="page" :label="page" :value="page" />
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="col-4 text-center">
                    <span class="text-muted d-block mt-2">
                        Showing {{ otherData.from }} to {{ otherData.to }} of {{ otherData.total }}
                    </span>
                </div>
                <div class="col-4">
                    <div style="float: right">
                        <el-pagination
                            v-model:current-page="rFilters.page"
                            :page-size="rFilters.per_page" 
                            :small="false"
                            layout="prev, pager, next"
                            :total="otherData.total"
                            @current-change="changePagination"
                        />
                    </div>
                </div>
            </div>
    </div>
    <div class="card-footer bg-white" v-if="showFooterWithoutPagination">
    </div>

</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import { per_pages } from '@/core/utils/Listing'
import { rFilters } from '@/store/composable/Reports'
import Headers from '@/views/NEW/Reports/Models/Headers.vue'
import AccountTable from '@/views/NEW/Reports/Models/AccountTable.vue'

const props = defineProps({
    data: {
        type: Function,
        required: true
    },
    otherData: {
        type: Function,
        required: false
    },
    dataHeaders: {
        type: Function,
        required: true
    },
    showTableTotal: {
        type: Boolean,
        required: false,
        default: false
    },
    tableTotal: {
        type: Number,
        required: false,
        default: 0
    },
    tableMessage: {
        type: String,
        required: false,
        default: ''
    },
    loading: {
        type: Boolean,
         required: true,
         default: true
    },
    isSr: {
        type: Boolean,
        required: false,
         default: true
    },
    srs: {
        type: Number,
        required: false,
        default: 0
    },
    showFooter: {
        type: Boolean,
        required: false,
        default: false
    },
    showFooterWithoutPagination: {
        type: Boolean,
        required: false,
        default: false
    },
    tableType: {
        type: String,
        required: false,
        default: 'default'
    },
    isDownloadHeader: {
        type: Boolean,
        required: false,
        default: false
    },
    counts: {
        type: Number,
        required: false,
        default: 0
    }
})

function changePagination(e) {
    rFilters.value.page = e;
}

const emit = defineEmits(['showTasks'])


function showTasks(value){
    console.log('click avve',value)
    emit('showTasks',value)
}
</script>
